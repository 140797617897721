header.top-bar {
  display: block;
  @include breakpoint(large) {
    display: flex;
  }
}

#top-menu {

  padding-top: 1rem;

  @include breakpoint(large) {
    padding-top: 0;
  }

  a {
    display: block;
  }

  ul.accordion-menu {
    margin: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {

      @include breakpoint(large) {
        display: inline-block;
        padding: 0.5rem 1.35rem 0.5rem 0.5rem;
        margin-right: 1rem;
      }

      display: block;
      position: relative;
      cursor: pointer;
      padding: 0.75rem;

      &.menu, &.region-menu {

        &.active {

          @include breakpoint(large) {
            right: 0;
            background: $white;
          }

          > a::after {
            border-color: $success-color transparent transparent;
          }
        }

        > a::after {
          display: block;
          width: 0;
          height: 0;
          border: inset 6px;
          content: '';
          border-bottom-width: 0;
          border-top-style: solid;
          border-color: $black transparent transparent;
          position: absolute;
          top: 1.1rem;
          right: 0.75rem;
          @include breakpoint(large) {
            right: 0;
          }
        }
      }

    }

    .dropdown {
      max-height: 0;
      position: static;
      background: $white;
      z-index: 99999;
      transition: max-height 0.1s linear;
      overflow: hidden;
      margin-top: 0.5rem;
      border-left: 5px solid $secondary-color;

      @include breakpoint(large) {
        position: absolute;
        top: 2.5rem;
        min-width: 185px;
        margin-top: 0;
      }

      &.show {
        @include breakpoint(large) {
          border: 1px solid $medium-gray;
        }
      }

      &.airports {
        min-width: 280px;
      }

      &.guides {
        min-width: 275px;
      }

      li {
        display: block;
        margin: 0;
        padding: 0;

        &[aria-expanded="true"] a::after {
          border-color: $success-color transparent transparent;
        }

        a {
          display: block;
          padding: 0.65rem 1rem;

          &::after {
            top: 1.2rem !important;
            right: 1rem !important;
            @include breakpoint(large) {
              top: 1.35rem !important;
            }
          }
        }
        .region-dropdown {
          position: static;
          display: block;
          max-height: 0px;
          padding: 0;
          overflow: hidden;
          border: none;
          margin: 0;
          background: $secondary-color;
          transition: max-height 0.3s linear;

          a {
            font-size: 0.9rem;
          }
        }

      }

    }

  }
}
