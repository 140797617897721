.reveal {
  
  .close {
    span {
      cursor: pointer;
    }
  }

  &.full {
    background-color: transparent;
  }
}

.reveal-overlay.show {
  display: block;

  .reveal {
    display: block;
    overflow: visible;
  }
}
