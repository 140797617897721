
// Resize reveal popup
@include breakpoint(odd) {
  .reveal.carpark {
    width: 400px !important;
  }
}

// Shared summary styles
%summary {
  .cell {
    background-color: $secondary-color;
    border-radius: $global-radius;
    text-align: center;
    padding: 1rem;

    .review-anchor {
      background-color: $secondary-color;
      border-radius: $global-radius;
      text-align: center;
      padding: 0.5rem 2rem;
      font-weight: bold;
      display: inline-block;
    }

    &.compare {
      .fas {
        margin-bottom: 0.5rem;
        display: block;
        color: $primary-color;
        padding-top: 0.25rem;
      }
    }

    .value {
      display: block;
      font-size: 2rem;
      font-weight: bold;
      .dollar {
        font-size: 1rem;
      }
      .total {
        color: $medium-gray;
        font-size: 1.5rem;
      }
    }

    .review-stars {
      display: block;
    }
  }
}

// Shared comment styles
.review-container {

  padding-top: 0.5rem;

  @include breakpoint(medium) {
    padding-top: 1rem;
  }

  .comment {
    background: $secondary-color;
    margin: 0.5rem 0;
    border-radius: $global-radius;
    .date {
      color: $dark-gray;
    }
  }
  .name {
    font-weight: bold;
    color: $primary-color;
  }
  .value {
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: bold;
    color: $primary-color;
  }
}

%carpark-logo {
  background-size: 100000px;
  padding: 0 !important;
  min-height: 6.5rem;
  border-radius: $global-radius;
  @include breakpoint(medium) {
    min-height: 8rem;
  }
  div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center center;
    border-radius: $global-radius;
    background-repeat: no-repeat;
    min-height: 6.5rem;
    @include breakpoint(medium) {
      min-height: 8rem;
    }
  }
}

// Carpark pages
.carpark-page {

  // Add summary box
  .carpark-logo {
    @extend %carpark-logo;
  }

  .aps-pattern {
    
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include breakpoint(medium) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  .quoteform-small {
    margin-top: 1rem;
  }

  .offer {
    font-size: 1.35rem;
    color: $white;
    margin-top: 1rem;
    border-top: 1px solid rgba($white, 0.2);
    padding-top: 0.5rem;
    span {
      font-size: 1rem;
    }
    strong {
      color: gold;
    }
  }

  .featured-review {
    margin-bottom: 0;
    i {
      font-weight: bold;
    }
    .qt {
      font-size: 3rem;
      line-height: 0;
      position: relative;
      top: 20px;
      left: 3px;
    }
  }

  #success_box {
    background: $primary-color;
    color: $white;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: $global-radius;
  }

  // Add summary box
  .summary {
    @extend %summary;
  }

  .info {

    h4 {
      padding: 1rem 0 0.25rem;
      i.fas {
        margin-right: 0.75rem;
        color: $primary-color;
      }
    }
  }

  .sidebar {
    margin-bottom: 0;
    padding-bottom: 0;
    .detail {
      margin: 1rem 0;
      border-radius: $global-radius;
      background: $secondary-color;
      padding: 1rem;

      p {
        border-bottom: 1px solid darken($secondary-color, 5%);
        padding-bottom: 1rem;
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
    .quote {
      background: $primary-color;
      border-radius: $global-radius;
    }

    .carpark-logo {
      margin-bottom: 1rem;
    }
  }

  .review.button i.fas {
    margin-right: 0.5rem;
  }

  .more {
    @include breakpoint(medium) {
      text-align: right;
    }
    a {
      font-size: 1.25rem;
      color: $success-color;
      text-decoration: underline;
      position: relative;
      bottom: 0.5rem;
    }
  }

  .nearby {
    text-align: center;
    i.fas {
      color: $primary-color;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
      @include breakpoint(medium) {
        margin-top: 2rem;
      }
    }
    a {
      text-decoration: underline;
      text-decoration-color: $primary-color;
    }
  }
}

#carpark_result_widget {

  .carpark {
    background-color: $secondary-color;
  }

  .loader {
    display: block;
    margin: 2rem auto;
  }

  .result {
    @extend %result;
    margin-top: 2rem;
  }

  #no_results {
    margin-top: 2rem;
  }

  #alt_results {
    margin-top: 2rem;
    text-align: center;
    font-weight: bold;
  }

  #no_results, .loading_text {
    display: block;
    text-align: center;
    font-weight: bold;
    padding: 0 0.5rem;
  }
  
  #full_results {
    text-align: center;
    display: block;
    text-decoration: underline;
    text-decoration-color: $primary-color;
    font-weight: bold;
    margin-top: 1rem;
  }
}

// Carpark review pages
.carpark-review-page {

  .quoteform {
    margin-bottom: 0;
    border-radius: $global-radius;

    .button {
      margin: 0 !important;
    }
  }

  // Add summary box
  .carpark-logo {
    @extend %carpark-logo;
  }

  .summary {
    @extend %summary;
    .description {
      background: none;
      text-align: left;
      font-weight: normal;
      padding: 0;
    }
  }
  .quick-link {
    font-weight: bold;
  }

  .alt-carparks {
    
    // margin: 3rem 1rem;
    background-color: $secondary-color;
    // background-color: $primary-color;
    border: 3px solid $primary-color;
    padding: 1rem 1.5rem;
    border-radius: $global-radius;

    h5 {
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      // color: $white;
    }

    .alt {

      .item {
        border-radius: $global-radius;
        padding: 0.5rem 0;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        max-height: 100%;
        width: 3rem;
        border-radius: $global-radius;
        // background-color: $white;
        // padding: 4px 6px;
      }
      
      a {
        text-decoration: underline;
        // color: $white;
        margin: 0 0.25rem 0 0.75rem;
      }
    }
  }

  #rating {

    .rating-summary {

      // @include breakpoint(medium) {
      //   border-left: 3px solid $secondary-color;
      //   padding-left: 2rem;
      // }

      .heading {
        font-size: 1.5rem;
        line-height: 1.75rem;
        float: left;
        padding-right: 1rem;
      }
  
      .value {
        line-height: 1.75rem;
        font-size: 2rem;
        font-weight: bold;
        text-align: right;
        padding: 0 0.25rem 0 1rem;
        .dollar {
          font-size: 1rem;
        }
        .total {
          color: $medium-gray;
          font-size: 1.5rem;
        }
        .number {
          display: block;
          font-size: 1rem;
        }
      }

    }

    .filter-heading {
      margin: 0.5rem 0 0.25rem;
      text-transform: uppercase;
      // font-weight: bold;
      // display: block;
      @include breakpoint(medium) {
        margin: 1rem 0 0.25rem;
      }
    }

    #review-select {
      margin: 0.5rem 0 0;
    }

    h4 {
      font-size: 1.5rem;
      margin-top: 1rem;
      @include breakpoint(medium) {
        margin-top: 2rem;
      }
    }

    .review-container .comment {
      animation: fadein 1s;
    }

    @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  
    .button {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      i.fas {
        margin-right: 0.5rem;
      }
    }

    .to-top i {
      color: $primary-color;
    }
  }

  .review_filter {
    font-size: 0.8rem;
    margin: 0;
    cursor: pointer;

    .cell {
      cursor: pointer;
    }

    .star-label {
      width: 2.45rem;
    }

    .percent-label {
      width: 3rem;
    }
    
    progress {
      margin: 0 0.25rem 0 0.5rem;
      height: 0.5rem;
    }

    progress[value] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &::-webkit-progress-bar {
        background-color: $secondary-color;
        border-radius: 1px;
        box-shadow: 0 2px 5px rgba($white, 0.25) inset;
      }
      &::-webkit-progress-value {
        background-color: $primary-color;
        border-radius: 1px;
        box-shadow: 0 2px 5px rgba($white, 0.25) inset;
      }
    }
  }

}
