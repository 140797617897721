
ul.jumplinks {
  list-style: none outside;
  margin: 1rem 0 0;
  li {
    display: inline-block;
    a {
      display: block;
      padding: 0 1rem;
      border-right: 1px solid #FFF;
      color: #FFF;
      text-decoration: underline;
    }
    &:last-of-type a {
      border-right: none;
    }
    @include breakpoint(small only) {
      display: block;
      a {
        border-right: none;
      }
    }
  }
}

.apt-header-right {
  // background-color: rgba(black, 0.4);
}

.airport-header-review {
  padding: 1rem 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.sec-pad-top {
  padding-top: 1.5rem !important;
  @include breakpoint(odd) {
    padding-top: 2rem !important;
  }
  @include breakpoint(medium) {
    padding-top: 3rem !important;
  }
  @include breakpoint(large) {
    padding-top: 3.5rem !important;
  }
}

.sec-pad-btm {
  padding-bottom: 1.5rem !important;
  @include breakpoint(odd) {
    padding-bottom: 2rem !important;
  }
  @include breakpoint(medium) {
    padding-bottom: 3rem !important;
  }
  @include breakpoint(large) {
    padding-bottom: 3.5rem !important;
  }
}

.quickquote {
  h2 {
    margin-bottom: 2rem
  }
}

.why-aps {

  .heading {
    padding: 0.25rem 0 0 3rem;
    margin-bottom: 0.85rem;
    line-height: 1;
    position: relative;

    .fa-icon {
      color: $primary-color;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    strong {
      font-size: 1.25rem;
    }
  }
}

.aps-tabs {

  .tab {

    &:hover {
      cursor: pointer;
    }

    &.is-active {
      background-color: $primary-color;
      border-bottom: 3px solid $primary-color;
    }

    font-size: 0.75rem;
    padding: 0.5rem;
    font-weight: bold;
    border-right: 3px solid $white;
    border-bottom: 3px solid $white;
    background-color: $dark-gray;
    color: $white;

    @include breakpoint(medium) {
      font-size: 1rem;
    }

    &:last-child {
      border-right: none;
    }
  }

  .content {
    display: none;
    border: 4px solid $primary-color;

    .services {

      .service {
        padding: 1rem;

        h3 {
          // padding-left: 2.5rem;
          position: relative;

          .fa-icon {
            color: $primary-color;
            padding-right: 0.25rem;
            position: relative;
            bottom: 3px;
          }
        }

      }
    }

    &.is-active {
      display: block !important;
    }

    &.about {
      padding: 1rem;
      font-weight: 400;

      ul {
        padding-bottom: 1rem;
        font-weight: bold;
      }
    }
    
  }
}

.reviews {
  span.review-stars {
    display: block;
    i {
      text-shadow: 3px 3px 0 rgba(255,255,255,0.3);

      svg {
        width: 3rem;
        height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
      }
    }
  }
  blockquote {
    margin: 0 0 1rem;
    padding: 2rem 0 2rem 2rem;
  }
}

.aps-pattern {
  small {
    display: block;
  }
}

.apt_avg_price {
  color: $success-color;
  background-color: $white;
  padding: 0 5px;
  margin: 0 2px;
  display: inline-block;
  white-space: nowrap;
  .has-tip {
    border: none;
  }
  .ast {
    font-size: 75%;
    vertical-align: top;
  }
}

.simple-table {
  margin-top: 2rem;
}

.sorted-table {

  h3 {
    padding: 0.85rem 1.25rem 0.65rem;
    background-color: $primary-color;
    font-size: 1.1rem;
    color: $white;
    font-weight: bold;
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
    margin: 1rem 0 0;

    @include breakpoint(medium) {
      margin-top: 1.5rem;
    }
    @include breakpoint(large) {
      margin-top: 2rem;
    }

    .fa-icon {
      margin-right: 0.5rem;
    }
  }
}

.carpark-table {
  margin-bottom: 0.25rem;

  .has-tip {
    border-bottom: none;
  }

  .apt_carpark_price {
    button {
      margin: 0.25rem auto 0;
      display: block;
    }
  }
  thead {
    .fas {
      margin-right: 0.25rem;
    }
    small {
      font-size: 75%;
      vertical-align: top;      
    }
  }
  tbody {
    td {
      text-align: center;
    }
    tr {
      border-bottom: 1px solid $light-gray;
    }
    tr:nth-child(even) {
      background: #FFF;
    }
    .carpark_name {
      text-decoration: underline;
      text-decoration-color: $primary-color;
      text-align: left;
      @extend .normal;
    }
    .review {
      .review_stars {
        font-size: 80%;
        display: block;
      }
      a {
        display: block;
        font-size: 75%;
        @extend .normal;
      }
    }
    .price {
      font-size: 1.25rem;
      @include breakpoint(medium) {
        font-size: 1.5rem;
      }
    }
  }
}

.price-disclaimer {
  display: block;
  padding-top: 0.25rem !important;
}

.provider-list {
  img {
    width: auto;
    max-height: 100px;
  }
  h4 {
    margin-bottom: 3rem
  }
}

.airport-faqs {
  padding: 0.25rem;

  .accordion-item {
    margin: 0.125rem 0;
  }
}
