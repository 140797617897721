.partner-contact {
  &:nth-child(odd) {
    background: $secondary-color;
  }
}

.testimonial {
  max-width: 800px;
  background: $secondary-color;
  padding: 1rem;
  font-weight: bold;
  color: $primary-color;
  i {
    color: $black;
    font-size: 0.85rem;
  }
}

.note {
  display: block;
  background: rgba($primary-color, 0.15);
  padding: 1rem;
  margin-top: 2rem;
  .fa-icon {
    color: $primary-color;
  }
}

.contacts {
  padding: 5rem 0 3rem 0;
  .contbox {
    border: $code-border;
    border-radius: $global-radius;
    h3 {
      font-size: 1.2rem;
      margin: 0 0 2rem;
    }
    .topimg {
      display: block;
      margin-top: -65px;
      img {
        width: 100px;
        height: auto;
        background: $primary-color;
        padding: .5rem;
        border: 10px solid #FFF;
        border-radius: 100%;
        margin: 0 0 1rem;
      }
      .fa-icon {
        background: $primary-color;
        color: #FFF;
        border-radius: 100%;
        border: 10px solid #FFF;
        padding: 1rem;
        margin: 0 0 1rem;
      }
    }
    .accordion {
      margin-bottom: 2rem;
      ul {
        margin-bottom: 2rem;
      }
    }
    .part-box {
      transition: all .3s ease;
      padding: 1rem;
      background: $light-gray;
      img {
        max-height: 100px;
        width: auto;
      }
    }
    @include breakpoint(small only) {
      margin-bottom: 5rem;
      &:last-of-type {
        margin-bottom: 0
      }
    }
  }
}
