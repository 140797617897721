
@keyframes fa-spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.fa-icon {

  display: inline-block;
  position: relative;

  &.spin {
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
  }

  &.inverse {
    color: $white;
    fill: $white;;
  }

  svg {
    vertical-align: middle;
  }

  &.size-0x {
    bottom: calc(0.075rem / 2);
    svg {
      width: 0.75rem;
      height: 0.75rem;
      max-width: 0.75rem;
      max-height: 0.75rem;
    }
  }

  &.size-1x {
    bottom: calc(0.1rem / 2);
    svg {
      width: 1rem;
      height: 1rem;
      max-width: 1rem;
      max-height: 1rem;
    }
  }

  &.size-2x {
    bottom: calc(0.125rem / 2);
    svg {
      width: 1.25rem;
      height: 1.25rem;
      max-width: 1.25rem;
      max-height: 1.25rem;
    }
  }

  &.size-3x {
    bottom: calc(0.175rem / 2);
    svg {
      width: 1.75rem;
      height: 1.75rem;
      max-width: 1.75rem;
      max-height: 1.75rem;
    }
  }

  &.size-4x {
    bottom: calc(0.2rem / 2);
    svg {
      width: 2rem;
      height: 2rem;
      max-width: 2rem;
      max-height: 2rem;
    }
  }

  &.size-5x {
    bottom: calc(0.25rem / 2);
    svg {
      width: 2.5rem;
      height: 2.5rem;
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  }

  &.size-6x {
    bottom: calc(0.3rem / 2);
    svg {
      width: 3rem;
      height: 3rem;
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  &.size-7x {
    bottom: calc(0.4rem / 2);
    svg {
      width: 4rem;
      height: 4rem;
      max-width: 4rem;
      max-height: 4rem;
    }
  }
}
