// Results colors
$results-red: #B80203;
$results-green: #09BE0A;
$results-blue: #0196E2;
$results-orange: #FFA500;

%result {

  transition: width 0.5s linear;
  position: relative;

  &.lounge {

    .inner-lounge {

      box-shadow: 1px 1px 5px rgba($black, 0.5);
      background: $white;
      border-radius: $global-radius;
      margin-bottom: 1rem;
      padding-bottom: 0;
      overflow: auto;

      .logo {

        background-size: 100000px;
        background-position: bottom right;

        img {

          @include breakpoint(medium) {
            max-height: 12rem;
          }
          @include breakpoint(large) {
            max-height: 17rem;
          }
        }

      }

      .details {

        padding: 1rem;

        h5 {
          font-size: 1.4rem;
          height: auto;
          overflow: auto;
        }

        .whybookone {
          padding-bottom: 1rem;
          font-weight: 600;
          color: $dark-gray;
          font-size: 80%;

          @include breakpoint(medium) {
            max-width: 90%;
          }
  
          @include breakpoint(large) {
            max-width: 80%;
          }
        }

        .opening-times {
          padding-bottom: 1rem;
        }

        .price {
          font-weight: 900;
          font-size: 2.5rem;
          color: $primary-color;
          padding-right: 0.5rem;
          position: relative;
          top: 1.25rem;

          span {
            font-size: 1.25rem;
          }
        }

        .book-box {
          
          .button {
            margin: 0;
          }

          .provider {
            padding-right: 0.25rem;
            text-align: right;
          }
        }

        .badge.refund {
          background: darken($dark-gray, 20%);
          color: $white;
          font-size: 0.7rem;
          font-weight: bold;
          margin: 0.35rem 0.2rem 0 0;
          padding: 0.15rem 0.35rem;
          text-align: center;
          background: $alert-color;
          min-width: 6rem;
        }

      }

    }
  }







  .inner {
    box-shadow: 1px 1px 5px rgba($black, 0.5);
    background: $white;
    border-radius: $global-radius;
    overflow: visible;
    height: 100%;
    overflow: hidden;
    padding-bottom: 4.5rem;
    position: relative;



    &.featured {

      $featured-border-width: 4px;

      border: $featured-border-width solid $results-green;

      .featured-badge {
        position: absolute;
        top: -2px;
        width: 100%;
        background: $results-green;
        color: $white;
        font-weight: bold;
        font-size: 0.65rem;
        text-align: center;
        padding-bottom: 2px;
      }

      // Reduced logo images to make up for featured border
      .logo {

        height: 150px-$featured-border-width;
  
        @include breakpoint(odd) {
          height: 145px-$featured-border-width;
        }
        @include breakpoint(medium) {
          height: 120px-$featured-border-width;
        }
        @include breakpoint(large) {
          height: 110px-$featured-border-width;
        }
      }
    }



    .logo {

      height: 150px;
      width: 100%;
      background-size: 100000px;

      @include breakpoint(odd) {
        height: 145px;
      }
      @include breakpoint(medium) {
        height: 120px;
      }
      @include breakpoint(large) {
        height: 110px;
      }

      .provider {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }

    .header-bar {

      background: $primary-color;
      color: $white;
      width: 100%;
      padding: 0.5rem 0;
      font-weight: bold;

      .carpark-type {
        font-size: 0.8rem;
        text-align: left;
        padding: 0 0.25rem 0 1rem;
      }

      .reviews {

        text-align: center;
        padding: 0 1rem 0 0.25rem;
        line-height: 0.9rem;

        .stars {
          width: 4.5rem;
        }
        a {
          font-size: 0.55rem;
          display: inline-block;
          color: $light-gray;
        }
      }
    }

    .details {
      padding: 1rem;

      h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        @include breakpoint(odd) {
          font-size: 0.85rem;
          height: 40px;
        }
      }

      .amount {
        font-weight: 900;
        margin-bottom: 0.75rem;

        div {
          font-size: 2.5rem;
          color: $primary-color;
          line-height: 1.5rem;

          span {
            font-size: 1.25rem;
            &.gate {
              display: inline-block;
              width: 60%;
              color: $success-color;
              font-size: 1rem;
              font-weight: 600;
              text-decoration: line-through;
              padding-right: 0.2rem;
              @include breakpoint(odd) {
                height: 24px;
              }
            }
          }
        }
      }

      a.clickthrough {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
      }

      .badge-container {
        padding: 0 0.75rem;
      }

      .badge {
        background: darken($dark-gray, 20%);
        color: $white;
        font-size: 0.7rem;
        font-weight: bold;
        margin: 0.35rem 0.2rem 0 0;
        padding: 0.15rem 0.35rem;
        text-align: center;
        clear: both;
        float: left;

        &.bf-coupon {
          background: #222;
        }

        &.refund {
          background: $alert-color;
          min-width: 6rem;

          &.flexible {
            background: darken( $results-green, 12% );
          }
        }

        &.parkmark {
          background: #0082D6;
          img {
            width: 2rem;
            margin-right: 0.2rem;
            vertical-align: bottom;
          }
        }
      }
    }

    a.info {
      position: absolute;
      border-width: 2px;
      padding: 0.35rem 0 0.25rem;
      width: 60%;
      margin: 0 auto;
      bottom: 1rem;
      left: 20%;

      i.fa-icon {
        margin-right: 0.2rem;
      }
    }
  }

  .alts {
    display: none;
  }

  // Hotel Styles
  &.hotel {
    
    .inner {
      border-color: $results-blue;
      border-width: 4px;
      background: lighten( $results-blue, 50% );
    }

    .hotel-label {
      background: $results-blue;
      padding: 0.25rem 0 0.35rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
      color: $white;
      font-size: 0.75rem;
    }
    .heading {
      padding: 0.75rem 0.5rem;
      line-height: 1rem;
      font-size: 0.75rem;
      strong {
        display: block;
        padding-bottom: 0.25rem;
        font-size: 1rem;
      }
    }
    .details {

      h5 {
        height: auto;
      }

      .terms {
        padding-bottom: 0.5rem;
        small {
          display: block;
          font-size: 0.65rem;
          font-weight: bold;
          margin-bottom: 0.75rem;
          color: $dark-gray;
        }
      }
    }

  }

}

small.provider {
  font-weight: 600;
  color: $dark-gray;

  &.alts-available {

    cursor: pointer;

    svg {
      width: 11px;
      height: 18px;
      position: relative;
      top: 1px;
      left: 2px;
      color: $success-color;
      fill: $success-color;
    }
  }
}

.alts-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba($black, 0.95);
  box-shadow: 0px 3px 3px rgba($black, 0.5);
  color: $white;
  z-index: 999;

  .bf-coupon {
    font-weight: bold;
    background-color: black;
    padding: 0.2rem 0.7rem;
    text-align: center;
    font-size: 0.65rem;
  }

  .close {
    color: $white;
    fill: $white;
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
    width: 1.75rem;
    padding: 0.5rem;
    cursor: pointer;
  }

  .heading {
    padding: 0.75rem 1rem 1rem;
    i {
      font-size: 0.85rem;
      display: block;
      padding: 0 1.2rem 0 0;
      color: darken($white, 20%);
    }
    h5 {
      margin-bottom: 0;
    }
  }

  .provider-row {
    padding: 0.25rem 1rem;
    border-top: 1px solid lighten($black, 35%);

    &:last-child {
      border-bottom: 1px solid lighten($black, 35%);
    }

    .subGatePrice {
      font-size: 0.75rem;
      margin-left: 0.25rem;
      color: $success-color;
      text-decoration: line-through;
    }
  }
}
