
.primary-text {
  color: $primary-color;
}

// Fix Safari options overflowing
select {
  overflow: hidden;
}

.alert-text {
  color: $alert-color;
}
.green-text {
  color: green;
}

// Button overrides
.button {
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  &.success {
    color: $white;
    &:focus, &:active, &:hover {
      color: $white;
    }
  }
}

table {
  .small {
    @include breakpoint(small only) {
      font-size: 0.25rem;
    }
  }
}

form.subscribe {
  .button {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.breadcrumbs {
  margin-bottom: 0;
  padding-left: 0.25rem;
}

.blur {
  filter: blur(3px);
}

.disclaimer {
  font-style: italic;
  display: block;
  margin-bottom: 0.5rem;
  a {
    text-decoration: underline;
    text-decoration-color: $primary-color;
    color: $black;
  }
}

.error {
  color: $alert-color;
}

ol, ul {
  margin-bottom: 0;
}

menu {
  padding: 0;
}

// Review ratings
.review-stars {
  white-space: nowrap;
  i {
    color: gold;
  }
}

// Set background to textured APS theme
.aps-pattern {
  background-image: url(/images/cloud-header.jpeg);
  background-position-x: center;
  background-position-y: 100%;
  background-size: cover;
  color: $white;

  &.invert {
    background-image: url(/images/lounge-splash.jpg);
    color: $black;
  }

  &.bright {
    background-image: url(/images/cloud-header-bright.jpeg);
    background-position-x: center;
    background-position-y: 10%; 
  }
}

// Font-weights
.light {
  font-weight: 400;
}

.normal {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

// Global shadow & radius helpers
.shadow {
  box-shadow: $thumbnail-shadow;
}

.radius {
  border-radius: $global-radius;
}

// Fix form input heights
input, select {
  line-height: 1.35rem;
  &[readonly] {
    background: $white;
    box-shadow: none;
  }
}

// Loader between page transitions. (used for long running booking-related navigation)
#booking_loader {
  background-color: rgba(255,255,255,0.85);
  padding: 20% 0 1rem;
  .message {
    display: block;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.25rem;
    font-size: 1.5rem;
  }
  .loader {
    display: block;
    margin: 1rem auto;
  }
}

.loader-container {
  .loader {
    margin: 1rem auto 0;
    display: block;
  }
}

.siteerror {
  .airport_links {
    .grid-x {
      padding-bottom: 0.5rem;
    }
  }
  .bigtext {
    text-align: center;
    font-size: 8rem;
    line-height: 8rem;
    opacity: 0.2;
    font-weight: bold;
    @include breakpoint(medium) {
      font-size: 15rem;
      line-height: 15rem;
    }
  }
}

#map {
  position: relative;
  overflow: hidden;

  .button {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
  }

  .map-overlay {
    transition: opacity 0.15s linear;
    opacity: 0;
    background-color: rgba($primary-color, 0.75);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    color: $white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    vertical-align: middle;
    border-radius: $global-radius;

    .tag {
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      margin-top: -0.75rem;
    }

    .fa-icon {
      position: relative;
      bottom: 0.25rem;
      margin-right: 0.5rem;
    }

    &:hover {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: $global-radius;
  }
}
