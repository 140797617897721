.carpark-info {

  h2 {
    font-size: 1.25rem;
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }

  table {
    
    tr, td {
      text-align: left;
    }

    td .carpark {
      font-weight: bold;
      display: block;
      padding: 2px 0;
      text-decoration: underline;
      text-decoration-color: $primary-color;
      &:nth-child(odd) {
        color: darken($primary-color, 25%);
      }
    }
  }

  nav {
    a {
      font-weight: bold;
      font-size: 110%;
    }
  }
}
