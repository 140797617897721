.faq-sidebar {
  .widget {
    background: #F1F3F5;
    border-radius: $global-radius;
    padding: 0.9375rem;
  }
  ul {
    list-style: none outside;
    margin: 0;
    li {
      margin: 0 0 .5rem 0;
      a {
        display: block;
        position: relative;
        padding: .1rem 0;
        transition: all .3s ease;
        &:after {
          position: absolute;
          right: 0;
          top: 10px;
          width: 0;
          height: 0;
          border: inset 6px;
          content: '';
          border-bottom-width: 0;
          border-top-style: solid;
          border-color: $black transparent transparent;
          transform: rotate(-90deg);
        }
        &.is-active {
          color: $success-color;
          padding: .1rem 0 .1rem .5rem;
          border-left: 2px solid $success-color;
          &:after {
            border-color: $success-color transparent transparent;
          }
        }
      }
    }
  }
}

.group {
  margin: 0 0 3rem;
  h3 {
    margin: 0 0 2rem;
  }
}

.accordion {

  .accordion-title {
    position: relative;
    display: block;
    padding: 1rem 2.6rem 1rem 1rem;
    background: $light-gray;
    font-weight: bold;
    text-transform: uppercase;
    i.fa-icon {
      padding-right: 0.25rem;
    }
    &:after {
      position: absolute;
      content: '+';
      right: 1rem;
      top: 1rem;
    }
    &[aria-expanded="true"] {
      color: $success-color;
      &:after {
        content: '-';
      }
    }
  }

  .accordion-item {
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
  }

  .accordion-content {
    padding: 1rem;
    display: none;

    &.show {
      display: block;
    }
  }
}
