.top-bar {

  a {
    color: $black;
    font-weight: 600;
  }

  .logo {
    svg {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    span {
      font-size: 120%;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .menu-icon {
    float: right;
    position: relative;
    top: 0.35rem;
    right: 0.5rem;
    &:after {
      height: 3px;
      background: $black;
      box-shadow: 0 7px 0 $black, 0 14px 0 $black;
    }
  }

}
