

.blackfriday-banner {

  background-image: none;
  background-color: #222222;
  color: $white;
  margin-top: 2rem;
}

.bf-block {

  padding-top: 2rem !important;
  padding-bottom: 1rem !important;

  strong {
    font-size: 1.2rem;
    font-weight: 900;
  }

  .inline-list {
    padding: 0 0 1rem;
    font-weight: bold;
  }

  &.instructions img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 24rem;
    padding: 0.5rem 1rem;
  }
}

.bf-dates {

  .inner {
    border: 2px $primary-color solid;
    overflow: auto;
    padding: 1rem 1rem 2rem;

    img {
      padding: 1rem 1rem 0 0;
      width: 100%;
      max-width: 9rem;
    }
  }


  h3 {
    text-align: center;
    font-size: 1.25rem;
    padding: 1rem 0 0;
    font-weight: 900;
  }
  
  .dates {
  
    line-height: 1.5rem;

    strong {
      display: block;
      font-size: 1.45rem;
      padding-top: 1rem;
    }

    span {
      display: block;
      font-size: 1.35rem;
    }
  }

}

.trust-us {
  background-color: $secondary-color;
  padding: 0.5rem 0;

  br {
    display: none;
  }

  @include breakpoint(medium) {
    padding-bottom: 1.5rem;

    br {
      display: inline-block;
    }
  }

  h5 {
    font-weight: bold;
  }

  .point {
    background-color: $white;
    border-radius: $global-radius;
    margin-bottom: 1rem;
  }

  img {
    padding: 1rem;
    display: block;
    max-width: 7rem;
    margin: 0 auto;
  }

  .green {
    color: green;
  }
}

.bf-promo {
  background-color: #000000;
  img {
    width: 100%;
  }
}
