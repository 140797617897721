.flatpickr-wrapper {
  display: block !important;
}

#quote_widget.reveal {
  overflow: visible;
}

.quoteform {
  position: relative;
  text-align: left;
  button, .button {
    margin-bottom: 1rem;
  }
  input, select {
    background-color: $light-gray;
    cursor: pointer;
  }
  input[type="submit"] {
    @include breakpoint(odd down) {
      font-size: 90%;
    }
  }
  label {
    font-weight: bold;
    text-indent: 3px;
  }
  .grid-padding-x .cell {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .grid-margin-x .cell {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .datepicker {
    border-top-left-radius: $global-radius;
    border-bottom-left-radius: $global-radius;
  }
}

/*
 * Reveal widget form
 */
 .quoteform-widget {
   padding-top: 0;
   button.close {
     font-weight: bold;
     text-align: right;
     font-size: 2rem;
     position: relative;
     top: -0.5rem;
   }
 }


/*
 * Small banner quoteform specifics.
 */
.quoteform-small {

  margin-bottom: 0.5rem;

  .button {
    margin-bottom: 0;
  }

  .quote-error {
    border-radius: 0;
    margin-bottom: 0;
  }

  form {

    @include breakpoint(odd down) {
      @include xy-gutters( $grid-margin-gutters, padding, top bottom);
      .field {
        @include xy-gutters( $grid-margin-gutters, margin, bottom);
      }
    }

    &.mobile_only {
      @include xy-gutters( $grid-margin-gutters, padding, top bottom);
      .field {
        @include xy-gutters( $grid-margin-gutters, margin, bottom);
      }
    }

    &.responsive {
      @include breakpoint(medium) {
        margin-bottom: 1rem;
        @include xy-gutters( $grid-margin-gutters, padding, bottom);
        .field {
          @include xy-gutters( $grid-margin-gutters, margin, right);
        }
      }
    }

  }

  label {
  color: $white;
  font-size: 1rem;
  margin: 0;
  line-height: 1.35rem;
  @include breakpoint(odd down) {
    text-indent: 0;
  }
  }
  .input-group, select {
    margin: 0;

    .timepicker {
    border-radius: 0 $global-radius $global-radius 0;
    border-left: 0;
    text-align: center;
    }
  }
}

/*
 * Large quoteform specifics. - Home
 */
.quoteform-large, .quoteform-widget-static {
  border-radius: $global-radius;
  background: $white;
  padding: 1rem 1rem 6rem;
  margin: 0 auto 2rem;

  @include breakpoint(medium) {
    padding: 3rem 3rem 6rem;
  }

  @include breakpoint(small) {
    margin-bottom: 1rem
  }

  #tpwidget {
    background: $primary-dark;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    border-radius: 0 0 $global-radius $global-radius;
  }

}

/*
 * Static quoteform widget specifics
 */
.quoteform-widget-static {
  padding-top: 1rem;
  margin: 0;
}

.quote-error {
  margin-bottom: 0.5rem;
  cursor: auto;
  &:hover {
    background-color: $alert-color !important;
  }
  @include breakpoint(medium) {
    margin-bottom: 1rem;
  }
}
