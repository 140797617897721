.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: $black transparent transparent;
  position: absolute;
  top: 16px;
  right: 0;
}

#offCanvas {
  padding: 0.5rem 0;
  ul.menu li {
    display: block;
    width: 100%;
    position: relative;
    &[aria-expanded="true"] {
      background: $primary-color;
      border-bottom: 1px solid $white;
    }
    a {
      color: #FFF;
      font-weight: bold;
      &:after {
        right: 10px;
        border-color: #FFF transparent transparent;
      }
    }
  }
  li ul.submenu {
    padding: 0 1.5rem 0.7rem;
    li {
      &[aria-expanded="true"] {
        border-bottom: 0;
      }
      a {
        padding: 0.7rem 0;
      }
    }
    ul {
      background: rgba(0,0,0,0.05);
      padding: 0.7rem 1rem;
    }
  }
}

.off-canvas-content {
  .menu a {
    padding: 0.7rem 1.5rem;
  }
  ul.menu li {
    position: relative;
    &[aria-expanded="true"] a {
      color: $success-color;
      &:after {
        border-color: $success-color transparent transparent;
      }
    }
  }
  ul.submenu {
    position: absolute;
    text-align: center;
    min-width: 250px;
    border: 1px solid #CACACA;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    li a {
      color: $black !important;
      &:hover {
        color: $success-color !important;
      }
    }
  }
  ul.submenu ul {
    position: relative;
    border: 0;
    background: rgba(0,0,0,0.1);
    min-width: initial;
    width: 100%;
  }
  ul.submenu a {
    line-height: 1.2;
    &:after {
      right: 10px !important;
    }
  }
}

.hero {

  padding-top:1rem;
  padding-bottom:1rem;

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1rem;
  }

  @include breakpoint(medium) {

    padding-top:2rem;
    padding-bottom:2rem;

    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.25rem;
    }
  }
}

.aps-steps {

  padding-top: 2rem;
  padding-bottom: 2rem;

  @include breakpoint(medium) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  } 

  .step-number {
    background: $primary-color;
    border-radius: 90rem;
    color: #FFFFFF;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    font-weight: bold;
    line-height: 4rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  p {
    padding: 0 2rem;
  }
}

.reviews-widget {
  max-width: 60rem;
  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 4rem;
  } 
}

.quick-links {
  padding: 2rem 0 2rem;
  @include breakpoint(medium) {
    padding: 2rem 0 4rem;
  } 
  background-color: lighten($success-color, 50%);
  a {
    color: $black;
    text-decoration: underline;
  }
  .fa-icon {
    color: $primary-color;
    padding-right: 1rem;
  }
  .airports {
    margin-bottom: 2rem;
    .cell {
      padding: 0.25rem 0.5rem;
    }
  }
}

.features {
  max-width: 60rem;
  padding-top: 1rem;
  @include breakpoint(medium) {
    padding-top: 2rem;
  } 
  .fa-icon {
    color: $primary-color;
    display: block;
    padding-bottom: 1rem;
  }
  h3 {
    margin-bottom: 1rem;
    text-align: center;
    @include breakpoint(medium) {
      margin-bottom: 2rem;
    }
  }
}

.price-link {
  background-color: $secondary-color;
  font-weight: bold;

  a {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin: 1rem 0;
  }
  .fa-icon {
    margin-right: 0.5rem;
    @include breakpoint( small only ) {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}

.small-quote {
  padding: 1rem;
  margin: 1rem 0;
  @include breakpoint(medium) {
    padding: 2rem;
    margin: 2rem 0;
  }
  .quoteform-small {
    padding: 1rem;
    border-radius: $global-radius;
    max-width: 64rem;
    margin: 0 auto;
  }
}

.newsletter {
  color: $white;
  padding: 2rem 1rem;
  margin: 3rem auto 1.5rem;
  background: $primary-dark;
  max-width: 60rem;

  a {
    text-decoration: underline;
    color: $white;
  }
}

.site-features {
  background: $primary-color;
  color: $white;
  font-size: large;
  padding: 1rem 0;
  font-weight: bold;

  .fa-icon {
    fill: $white;
    margin-right: 0.25rem;
  }

  .grid-x {
    max-width: 60rem;
    margin: 0 auto;
  }
}

.travel-tips {
  max-width: 60rem;
}

// .site-features {
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   @include breakpoint(medium) {
//     padding-top: 2rem;
//     padding-bottom: 2rem;
//   }  
//   img {
//     max-width: 80%;
//     margin-bottom: 1rem;
//   }
//   p {
//     font-size: 85%;
//   }
//   a {
//     color: $success-color;
//   }
// }

.parking-types {

  max-width: 60rem;

  .item {
    h5 {
      padding: 1.25rem 0 0.5rem;
    }
  }

}

.partners-collage {
  background: #FCFCFC;
  border-bottom: 1px solid $light-gray;
  border-top: 1px solid $light-gray;
  // margin-bottom: 1.5rem;
  padding: 1rem;
  @include breakpoint(medium) {
    padding: 2rem 1rem;
  }
}

.blog-categories {
  max-width: 60rem;
  font-weight: bold;
  margin-bottom: 2rem;

  a {
    color: $white;
  }

  .cat {
    background-size: cover;
    color: $white;
  }

  p {
    margin: 0.2rem;
  }

}

.travel-tips {

  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 4rem;
  }
  @include breakpoint(large) {
    margin-top: 8rem;
  }

  span {
    font-size: 1rem;
    color: $dark-gray;
  }

  .main-post {
    position: relative;
    background-size: cover;
    min-height: 22rem;

    .inner {
      background: lighten($primary-color, 50% );
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 1rem;

      a {
        color: $success-color;
        float: right;
        font-weight: bold;
      }
    }
  }

  .single-post {
    border: 1px solid $medium-gray;
    padding: 1rem;

    a {
      color: $black !important;
    }

    img {
      width: 120px;
      float: left;
      margin: 0.2rem 1rem 0 0;
    }
  }

}

